import React from 'react';
import { BrowserRouter }  from 'react-router-dom';
import Navbar from './components/navbar/navbar';
import TopNavbar from './components/topNavbar/topNavbar';
import Layout from './components/Layout';
import Routes from './Routes';
import Footer from './components/footer/Footer';
import FooterReact from './components/footer/FooterReact';
import "./app.css";


function App() {
  return (
    <BrowserRouter>
      <div className="app">
        <Layout >
          <TopNavbar />
          <Navbar />
          <Routes className="page-content"/>
        </Layout>
      </div>
      <FooterReact />
    </BrowserRouter>
  );
}

export default App;
