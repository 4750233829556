import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import './topNavbar.css';

const TopNavbar = () => {
    return (
        <Navbar className="top-navbar">
        <PhoneIcon style={{ fontSize: 20 }} className="phone-icon"/>
        <span className="phone-number">021.436.3484</span>
        <span className="separator-line"></span>
        <MailOutlineIcon style={{ fontSize: 20 }} className="mail-icon"/>
        <span className="email">um02240@mapn.ro</span>
            
        </Navbar>
    )
}

export default TopNavbar;