import React from 'react'
import "./popup.css";
import metodologie from "./metodologie.PDF";
import rezultate from "./rezultateFinale.PDF";
import rezultate2 from "../admitere/informatiiAdmitere/rezultate2.PDF";
import anexaMetodologie from '../admitere/informatiiAdmitere/anexaMetodologie.PDF';
import rezultateAsistent from './rezultateAsistent.pdf';

function Popup(props) {

    const handleClickAici = () => {
        props.blur()
    }


    return (
        <div className={props.showPopup ? "popup" : "hidePopup"}>
            <button className="exit-button" onClick={props.blur}>X</button>
            <div className="textWrapper">
            {/* <span className="top-text"><strong><h3>REZULTATELE</h3></strong> selecţiei dosarelor de concurs pentru încadrarea postului de Asistent medical la Cabinetul medical</span>
            <a href={rezultateAsistent} target="_blank" className="bottom-text" onClick={handleClickAici}>Deschide</a> */}
            <p > Vezi <span> <strong><a className="bottom-text" href={typeof window !== "undefined" ? `${window.location.origin}/anunturi` : ""}> AICI </a></strong></span> rezultatele ultimelor selecții !</p>
            </div>   
        </div>
    )
}

export default Popup
