import React from 'react'
import "./heraldica.css"
import ecusonCombat from "./ecusonCombat.jpg"
import ecusonOras from "./ecusonOras.jpg"
import insemnHeraldic from "./insemnHeraldic.jpg"
import insignaBuzunar from "./insignaBuzunar.jpg"
import medalie from "./medalie.jpg"
import steag from "./steag.jpg"

function Heraldica() {
    return(
        <div className="heraldica">
            <h3>Heraldică</h3>
            <div className="insemne">
                        <span>Steag</span>
                        <img src={steag}></img>
                        <span>Medalie</span>
                        <img src={medalie}></img>
                        <span>Ecuson oraș</span>
                        <img src={ecusonOras}></img>
                        <span>Însemn heraldic</span>
                        <img src={insemnHeraldic}></img>
                        <span>Ecuson combat</span>
                        <img src={ecusonCombat}></img>
                        <span>Insignă buzunar</span>
                        <img src={insignaBuzunar} id="bottom-picture"></img>
            </div>
            
        </div>
    )
}

export default Heraldica