import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import { Link } from 'react-router-dom';
import "./footerReact.css"

const FooterPage = () => {
  return (
    <MDBFooter color="blue" className="font-small pt-4 mt-4" className="footer">
      <MDBContainer fluid className="text-center text-md-left">
        <MDBRow>
          <MDBCol md="4" className="footerLeft">

          <span className="footer-div--title">Informaţii utile</span>
            <p className="footer-div--text">Şcoala Militară de Maiştri Militari şi Subofiţeri de Logistică „Gheorghe Lazăr” <br/> este o instituţie militară de învăţământ postliceal, subordonată Comandamentului Logistic Întrunit.
            </p>
            <ul className="footer-div--list">
                <li className="footer-div--listItem">
                    <Link to="/misiune" className="footer-div--listItem--label">Despre noi</Link>
                    
                </li>
                <li className="footer-div--listItem">
                     <Link to="/infoAdmitere" className="footer-div--listItem--label">Invatamant</Link>
                </li>
                <li className="footer-div--listItem">
                     <Link to="/infoAdmitere" className="footer-div--listItem--label">Admitere</Link>
                </li>
                <li className="footer-div--listItem">
                     <Link to="/contact" className="footer-div--listItem--label">Contact</Link>
                </li>

            </ul>
            <br/>
            
          </MDBCol>

          <MDBCol md="4">

          <span className="footer-div--title">Adresa şcolii</span>
            <p className="footer-div--text">Şcoala Militară de Maiştri Militari şi Subofiţeri de Logistică „Gheorghe Lazăr” <br/> este o instituţie militară de învăţământ postliceal, subordonată Comandamentului Logistic Întrunit.
            </p>
            <span className="footer-div--listItem">Adresa: Strada Crinului 2, Chitila 077045</span><br/>
            <span className="footer-div--listItem">Telefon: 021.436.3484</span><br/>
            <span className="footer-div--listItem">Fax: 021.436.3484</span><br/>
            <span className="footer-div--listItem">Email: um02240@mapn.ro</span>
            

          </MDBCol>
        
          <MDBCol md="4" >
          <span className="footer-div--title">Link-uri utile</span>
            <ul className="footer-div--list">
                <li className="footer-div--listItem1">
                   <a href="https://www.mapn.ro/" className="footer-div--listItem">Ministerul Apărării Naţionale</a> 
                </li>
                <li className="footer-div--listItem">
                    <a href="https://www.logmil.ro/" className="footer-div--listItem">Comandamentul Logistic Întrunit</a> 
                </li>
                <li className="footer-div--listItem">
                    <a href="http://www.ncoacademy.ro/" className="footer-div--listItem">Şcoala Militară de Maiştri Militari şi Subofiţeri a Forţelor Terestre "Basarab I"</a> 
                </li>
                <li className="footer-div--listItem">
                    <a href="http://www.smmmsfa.ro/" className="footer-div--listItem">Şcoala Militară de Maiştri Militari şi Subofiţeri a Forţelor Aeriene "Traian Vuia"</a> 
                </li>
                <li className="footer-div--listItem">
                    <a href="http://www.smcis.ro/" className="footer-div--listItem">Şcoala Militară de Maiştri Militari şi Subofiţeri pentru Comunicaţii, Tehnologia informaţiei şi Apărare cibernetică</a> 
                </li>
                <li className="footer-div--listItem">
                    <a href="http://www.cantemircml.ro/" className="footer-div--listItem">Colegiul Naţional Militar "Dimitrie Cantemir" Breaza</a> 
                </li>
                <li className="footer-div--listItem">
                    <a href="http://www.cmilaicuza.ro/" className="footer-div--listItem">Colegiul Naţional Militar "Alexandru Ioan Cuza" Constanţa</a> 
                </li>
                <li className="footer-div--listItem">
                    <a href="http://www.colmil_sm.forter.ro/" className="footer-div--listItem">Colegiul Naţional Militar "Ştefan cel Mare" Câmpulung Moldovenesc</a> 
                </li>
                <li className="footer-div--listItem">
                    <a href="http://www.colmil-mv.ro/" className="footer-div--listItem">Colegiul Naţional Militar "Mihai Viteazul" Alba Iulia</a> 
                </li>
                <li className="footer-div--listItem">
                    <a href="http://ismb.edu.ro/" className="footer-div--listItem">Inspectoratul Scolar al Municipiului Bucuresti</a> 
                </li>

            </ul>
          </MDBCol>


          
        </MDBRow>

        

      </MDBContainer>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} Copyright: SMMMSL
        </MDBContainer>
      </div>
    </MDBFooter>
  );
}

export default FooterPage;