import { Slide } from '@material-ui/core';
import "./home.css";
import Slideshow from "./slideshow/Slideshow";
import MenuBookIcon from '@material-ui/icons/MenuBook';
import Popup from './Popup';
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';

function Home() {
    const [show, setShow] = useState(true)  // set to "true" to show popup at page load
     const closePopup = () => {
         setShow(false)
     }

    return (
        <div className="home">
            <Slideshow />
            <div className={show ? "popupWrapper" : "noPopupWrapper"}>
            <Popup showPopup={show} blur={closePopup}/>
            </div>
                <div className="info-home">
                <div className={show ? "blur" : "noBlur"}>

              
                </div>


                    <Grid container xs={12} >
                            <Grid item xs={12}  md={6}>
                                 <div className="info-item">
                                    <MenuBookIcon className="bookIcon"/>
                                    <span>Misiune</span>
                                    <p>Şcoala Militară de Maiştri Militari şi Subofiţeri de Logistică „Gheorghe Lazăr”,
                                    subordonată Comandamentului logistic întrunit, asigură  formarea iniţială 
                                    prin pregătire postliceală a maiştrilor militari şi a subofiţerilor de logistică 
                                    ca luptători, lideri ai structurilor de la baza ierarhiei militare şi specialişti
                                    în asigurarea serviciilor de sprijin logistic, exploatarea, repararea, întreţinerea 
                                    echipamentelor din dotare, potrivit nevoilor armatei şi altor beneficiari, atât la pace,
                                    cât şi la criză şi război, precum şi participarea la formarea profesională continuă a personalului 
                                    de logistică.
                                    </p>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>

                                    <div className="info-item">
                                            <MenuBookIcon  className="bookIcon"/>
                                            <span>Viziune</span>
                                            <p>Şcoala Militară de Maiştri Militari şi Subofiţeri de Logistică „Gheorghe Lazăr” îşi propune să promoveze
                                            o educaţie modernă, care să îi ajute pe elevi să devină specialişti militari în domeniile 
                                            funcţionale ale logisticii, să promoveze valorile şi tradiţiile poporului român, să fie factori 
                                            activi în consolidarea democraţiei în România. 

                                            </p>
                                        </div>
                                
                            </Grid>

                    </Grid>
                
                    {/* <div className="center-wrapper">
                    
                                <div className="info-item">
                                    <MenuBookIcon className="bookIcon"/>
                                    <span>Misiune</span>
                                    <p>Şcoala Militară de Maiştri Militari şi Subofiţeri de Logistică „Gheorghe Lazăr”,
                                    subordonată Comandamentului logistic întrunit, asigură  formarea iniţială 
                                    prin pregătire postliceală a maiştrilor militari şi a subofiţerilor de logistică 
                                    ca luptători, lideri ai structurilor de la baza ierarhiei militare şi specialişti
                                    în asigurarea serviciilor de sprijin logistic, exploatarea, repararea, întreţinerea 
                                    echipamentelor din dotare, potrivit nevoilor armatei şi altor beneficiari, atât la pace,
                                    cât şi la criză şi război, precum şi participarea la formarea profesională continuă a personalului 
                                    de logistică.
                                    </p>
                                </div>
                                <div className="info-item">
                                    <MenuBookIcon  className="bookIcon"/>
                                    <span>Viziune</span>
                                    <p>Şcoala Militară de Maiştri Militari şi Subofiţeri de Logistică „Gheorghe Lazăr” îşi propune să promoveze
                                    o educaţie modernă, care să îi ajute pe elevi să devină specialişti militari în domeniile 
                                    funcţionale ale logisticii, să promoveze valorile şi tradiţiile poporului român, să fie factori 
                                    activi în consolidarea democraţiei în România. 

                                    </p>
                                </div>
                    </div> */}
                </div>
                <p className="homepage-text"><i>Şcoala Militară de Maiştri Militari şi Subofiţeri de Logistică, subordonată Comandamentului logistic întrunit, asigură  formarea iniţială prin pregătire postliceală a maiştrilor militari şi a subofiţerilor de logistică ca luptători, lideri ai structurilor de la baza ierarhiei militare şi specialişti în asigurarea serviciilor de sprijin logistic, exploatarea, repararea, întreţinerea echipamentelor din dotare, potrivit nevoilor armatei şi altor beneficiari, atât la pace, cât şi la criză şi război, precum şi participarea la formarea profesională continuă a personalului de logistică.
                </i>
           </p>
        </div>
    )
}

export default Home
