import React from 'react'
import "./istoric.css"

function Istoric() {
    return (
        <div className="viziune">
        <h1>Scurt istoric al învățământului de formare a maiștrilor militari și subofițerilor de logistică</h1>
            <p className="viziune-text">
            Procesul de dezvoltare în timp a armatei, prin perfecţionările succesive aduse înzestrării şi instruirii, a strategiei şi tacticii purtării războaielor au reclamat formarea unui mare număr de cadre în toate domeniile şi, deopotrivă, în cel al asigurării condiţiilor de trai necesare efectivelor.
</p> 
<p className="viziune-text">
Creşterea în volum şi complexitate a sarcinilor administrative ale armatei a dus tot mai mult la concluzia că acestea nu pot fi îndeplinite de calitate numai prin aptitudinile gospodăreşti ce caracterizau ofiţerii, maiştrii militari şi subofiţerii selecţionaţi în acest scop. Astfel, se resimţea nevoia unei pregătiri instituţionalizate, teoretice şi practice, care să răspundă tuturor cerinţelor legate de gestionarea bunurilor materiale şi întreţinerea corespunzătoare a efectivelor.
</p> 
<p className="viziune-text">
Primul Război Mondial a scos convingător în evidenţă rolul subofiţerilor de administraţie şi intendenţă, ceea ce a determinat luarea măsurilor necesare pentru îmbunătăţirea activităţii de selecţionare şi pregătirea acestei importante categorii de cadre militare.
</p> 
<p className="viziune-text">
Măsurile luate în acest scop au fost materializate în reînfiinţarea sau înfiinţarea instituţiilor de învăţământ de specialitate necesare, cum au fost: Secţia intendenţei pe lângă Şcoala Superioară de Război, Şcoala Ofiţerilor de Administraţie, Şcoala Specială de Ofiţeri de Administraţie, Şcoala Pregătitoare de Subofiţeri de Administraţie. 
</p> 
<p className="viziune-text">
Pregătirea subofiţerilor necesari serviciilor armatei s-a executat în cadrul Şcolii de Subofiţeri de Administraţie din Bucureşti. Între anii 1925 – 1930 aceasta se numea Şcoala Plutonierilor de Administraţie şi a funcţionat temporar (1927 - 1928) la Bacău, pe lângă Şcoala de Ofiţeri de Administraţie. 
</p> 
<p className="viziune-text">
După cel de Al Doilea Război Mondial învăţământul militar de specialitate a cunoscut ample mutaţii, atât ca dinamică şi forme de organizare, cât şi în ce priveşte conţinutul său.
</p> 
<p className="viziune-text">
În baza Ordinului Marelui Stat Major, în anul 1948 se reînfiinţează, ca şcoală aparte în garnizoana Timişoara–Şcoala Militară de Ofiţeri şi Subofiţeri de Administraţie, care şi-a început activitatea efectiv la 30 octombrie 1948, organizând programe de studii cu o durată ce a variat de la an la an, în funcţie de necesităţile armatei.
</p> 
<p className="viziune-text">
Prin Ordinul Marelui Stat major nr.51904 din 31.08.1948, în septembrie 1949, Şcoala Militară de Ofiţeri şi Subofiţeri de Administraţie, este dislocată din  garnizoana Timişoara în garnizoana  Craiova, ocupând clădirile Liceului Militar.
</p>
<p className="viziune-text">
La data de 8 noiembrie 1949, Şcoala Militară de Ofiţeri şi Subofiţeri de Administraţie, se separă în Şcoala de ofiţeri contabili şi birou şi Şcoala de subofiţeri contabili şi birou.
</p>
<p className="viziune-text">
La data de 8 noiembrie 1949, Şcoala Militară de Ofiţeri şi Subofiţeri de Administraţie, se separă în Şcoala de ofiţeri contabili şi birou şi Şcoala de subofiţeri contabili şi birou.
</p>
<p className="viziune-text">
În anul 1950 corpul subofiţerilor se transformă corpul sergenţilor. Tot în acelaşi an, la 30 decembrie, prin desfiinţarea Şcolii de Sergenţi Contabili de Birou, un număr de 124 de elevi, pe baza rezultatelor obţinute, au fost trecuţi în anul II la Şcoala de Ofiţeri Financiari şi de Intendenţă, iar ceilalţi au trecut la cursul de specializare de două luni , pe lângă aceeaşi şcoală. 
</p>
<p className="viziune-text">
Numărul mare de subofiţeri de intendenţă necesar în armată în multe compartimente de activitate a fost pregătit în cadrul Şcolii de Subofiţeri de Intendenţă şi Administraţie, până la 25.06.1960, când s-a contopit cu Şcoala de Subofiţeri de Infanterie, dar ca secţie separată.
</p>
<p className="viziune-text">
Organizarea învăţământului, ca şi întreaga viaţă economico-socială, era supusă unei stricte centralizări şi tutelări, iniţiativa instituţiilor de învăţământ fiind sistematic limitată.
</p>
<p className="viziune-text">
În perioada 1961-1987 pregătirea maiştrilor militari şi subofiţerilor de intendenţă s-a desfăşurat în Şcoala Militară de  Maiştri Militari şi Subofiţeri „Gheorghe Lazăr” Sibiu.
</p>
<p className="viziune-text">
În ce priveşte calitatea învăţământului militar de specialitate, la toate nivelurile, s-a situat permanent, corespunzător răspunderilor ce i-au revenit faţă de destinul armei pentru care au militat. Marele număr de cadre valoroase care au slujit învăţământul militar pe linie de intendenţă, a dovedit competenţă, dăruire şi pasiune în activitatea didactică.
</p>
<p className="viziune-text">
După evenimentele din Decembrie 1989, au fost luate importante măsuri care au creat condiţii ca învăţământul de specialitate să se desfăşoare cu eficienţă maximă. Creşterea duratei de şcolarizare la toate nivelurilor, refacerea programelor de pregătire în cadrul cărora ponderea o reprezintă pregătirea de specialitate, crearea unor raporturi optime între activităţile teoretice şi cele practice etc. sunt măsurile care au asigurat formarea unor cadre cu o temeinică pregătire, apte să îndeplinească în cele mai bune condiţii atribuţiile ce le revin.
</p>
<p className="viziune-text">
În perioada 1987-1991 pregătirea ofiţerilor, maiştrilor militari şi subofiţerilor de intendenţă s-a desfăşurat în Şcoala Militară de Ofiţeri, Maiştri Militari şi Subofiţeri „Gheorghe Lazăr”.
</p>
<p className="viziune-text">
În anul 1991 s-a în fiinţat Institutul Militar de Intendenţă şi Finanţe  „Gheorghe Lazăr”, în cadrul căruia funcţiona Secţia formare a maiştrilor militari şi subofiţerilor de intendenţă.
</p>
<p className="viziune-text">
În conformitate cu  O.G.-10 din 5 martie 1996 al ministrului apărării naţionale, prin care începând cu data de 1 august 1996 a luat fiinţă Şcoala Militară de Maiştri Militari şi Subofiţeri a Trupelor de Uscat, la Piteşti, cu misiunea de a realiza pregătirea comună a elevilor maiştri militari şi subofiţeri din anul I de studii, pentru toate armele şi specialităţile trupelor de uscat, precum şi ale altor beneficiari. În consecinţă, din septembrie 1996, elevii maiştri militari şi subofiţeri admişi la profilul intendenţă, administraţie şi construcţii au desfăşurat pregătirea militară generală, anul I, la Piteşti, urmând ca pregătirea de specialitate anul II pentru maiştri şi anul semestrul II pentru subofiţeri să se execute în Şcoala de Aplicaţie pentru Logistică „General Constantin Zaharia”.
</p>
<p className="viziune-text">
În forma prezentată mai sus pregătirea maiştrilor militari de intendenţă şi construcţii şi a subofiţerilor de intendenţă, administraţie şi construcţii s-a desfăşurat până 01 august 2020, când s-a înfiinţat Şcoala Militară de Maiştri Militari şi Subofiţeri de Logistică.
</p>

        </div>
    )
}

export default Istoric
