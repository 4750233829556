import React from 'react';
import "./organizare.css";
import orgpic from "./Organigrama.png"
function Organizare() {
    return (
        <div className="organizare">
            <img src={orgpic} className="centerImage"></img>
        </div>
    )
}

export default Organizare
