import React from 'react'
import "./footer.css"
import { Link } from 'react-router-dom';

function Footer() {
    return (
    <div className="footer">
        <div className="footer-div">
            <span className="footer-div--title">Informaţii utile</span>
            <p className="footer-div--text">Şcoala Militară de Maiştri Militari şi Subofiţeri de Logistică „Gheorghe Lazăr” este o instituţie militară de învăţământ postliceal, subordonată Comandamentului Logistic Întrunit.
            </p>
            <ul className="footer-div--list">
                <li className="footer-div--listItem">
                    <Link to="/misiune" className="footer-div--listItem--label">Despre noi</Link>
                    
                </li>
                <li className="footer-div--listItem">
                     <Link to="/invatamant" className="footer-div--listItem--label">Invatamant</Link>
                </li>
                <li className="footer-div--listItem">
                     <Link to="/admitere" className="footer-div--listItem--label">Admitere</Link>
                </li>
                <li className="footer-div--listItem">
                     <Link to="/contact" className="footer-div--listItem--label">Contact</Link>
                </li>

            </ul>
                
            
        </div>

        <div className="footer-div">
            <span className="footer-div--title">Adresa şcolii</span>
            <p className="footer-div--text">Şcoala Militară de Maiştri Militari şi Subofiţeri de Logistică „Gheorghe Lazăr” este o instituţie militară de învăţământ postliceal, subordonată Comandamentului Logistic Întrunit.
            </p>
            <span className="footer-div--listItem">Adresa: Strada Crinului 2, Chitila 077045</span>
            <span className="footer-div--listItem">Telefon:</span>
            <span className="footer-div--listItem">Fax:</span>
            <span className="footer-div--listItem">Email:</span>

            
        </div>

        <div className="footer-div">
            <span className="footer-div--title">Link-uri utile</span>

            <ul className="footer-div--list">
                <li className="footer-div--listItem">
                   <a href="https://www.mapn.ro/" className="footer-div--listItem">Ministerul Apărării Naţionale</a> 
                </li>
                <li className="footer-div--listItem">
                    <a href="https://www.logmil.ro/" className="footer-div--listItem">Comandamentul Logistic Întrunit</a> 
                </li>
                <li className="footer-div--listItem">
                    <a href="http://www.ncoacademy.ro/" className="footer-div--listItem">Şcoala Militară de Maiştri Militari şi Subofiţeri a Forţelor Terestre "Basarab I"</a> 
                </li>
                <li className="footer-div--listItem">
                    <a href="http://www.smmmsfa.ro/" className="footer-div--listItem">Şcoala Militară de Maiştri Militari şi Subofiţeri a Forţelor Aeriene "Traian Vuia"</a> 
                </li>
                <li className="footer-div--listItem">
                    <a href="http://www.smcis.ro/" className="footer-div--listItem">Şcoala Militară de Maiştri Militari şi Subofiţeri pentru Comunicaţii, Tehnologia informaţiei şi Apărare cibernetică</a> 
                </li>
                <li className="footer-div--listItem">
                    <a href="http://www.cmilaicuza.ro/" className="footer-div--listItem">Colegiul Naţional Militar "Alexandru Ioan Cuza" Constanţa</a> 
                </li>
                <li className="footer-div--listItem">
                    <a href="http://www.colmil_sm.forter.ro/" className="footer-div--listItem">Colegiul Naţional Militar "Ştefan cel Mare" Câmpulung Moldovenesc</a> 
                </li>
                <li className="footer-div--listItem">
                    <a href="http://www.cantemircml.ro/" className="footer-div--listItem">Colegiul Naţional Militar "Dimitrie Cantemir" Breaza</a> 
                </li>
                <li className="footer-div--listItem">
                    <a href="http://www.colmil-mv.ro/" className="footer-div--listItem">Colegiul Naţional Militar "Mihai Viteazul" Alba Iulia</a> 
                </li>
                <li className="footer-div--listItem">
                    <a href="http://ismb.edu.ro/" className="footer-div--listItem">Inspectoratul Scolar al Municipiului Bucuresti</a> 
                </li>

            </ul>
                
            
        </div>
    </div>
    )
}

export default Footer
