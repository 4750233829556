import React from 'react'
import "./infoAdmitere.css"
import locuriAdmitere from "./locuri_2021-2022.pdf";
import metodologie from "./metodologie.PDF";
import rezultateInt from './rezultateIntendenta.pdf';
import rezultateAdm from './rezultateAdministratie.pdf';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

//pdf-uri corecte
import subAdministratie from './subAdministratie.pdf';
import subConstructii from './subConstructii.pdf';
import subIntendenta from './subIntendenta.pdf';
import rezultateMaistri from './rezultateMaistri.PDF';
import anexaMetodologie from './anexaMetodologie.PDF';
import locuriDisponibile from './locuriDisponibile.pdf';
import rezultate2 from './rezultate2.PDF';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: "5px",
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

const InfoAdmitere = () => {



      const accordeonClasses = useStyles();

    return (

            <div className="info">
                <Grid container item xs={12}>
                <h1 className="mainTitle">Informații Admitere</h1>
                </Grid>

                <Grid container xs={12}>
            <div className="accordionWrapper">
                <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="accordionLabelFirstItem"
              >
                <Typography className={accordeonClasses.heading}>
                  <h2 className="accordionLabel">
                  În atenția candidaților
                </h2></Typography>
              </AccordionSummary>
              <AccordionDetails>
                    <div >  
                      <p>Documente necesare la prezentarea la Centrul Zonal de Selecție și Orientare candidații vor avea asupra lor următoarele documente obligatorii：</p>
                        <ul className="accordionUl">
                          <li>
                            <span className="subTitle"> - actul de identitate în original</span>
                          </li>
          
                          <li>
                            <span className="subTitle"> - adeverință medicală necesară susținerii selecției ( se vor trece sintagmele "clinic sănătos" și "nu se află în evidență/observație cu boli cronice, psihice/neuropsihice", iar la motivul eliberăii va fi consmenată sintagma "pentru susținerea de probe sportive"</span>
                          </li>

                          <li>
                            <span className="subTitle"> - ustensile de scris cu pastă/cerneală de culoare albastră</span>
                          </li>

                          <li>
                            <span className="subTitle"> - echipament sportiv și încălțări de sport adecvate</span>
                          </li>

                          <li>
                            <span className="subTitle"> - măști de protecție sanitară</span>
                          </li>

                        </ul>

                    </div>
              
              </AccordionDetails>
            </Accordion>

            </div>                
                </Grid>

                <Grid xs={12}>
                       <p></p>
                   </Grid>


                <Grid container item xs={12} justify="center">
                        <div className="linkContainer1">
                            <a className="subTitle" href={rezultate2} target="_blank">Rezultate obținute de candidați la concursul de admitere - 2021 sesiunea a II-a</a>
                        </div>
                </Grid>


                <Grid xs={12}>
                       <p></p>
                   </Grid>


                <Grid container item xs={12} justify="center">
                        <div className="linkContainer1">
                            <a className="subTitle" href={rezultateMaistri} target="_blank">Rezultate obținute de candidați la concursul de admitere - maiștri militari 2021</a>
                        </div>
                </Grid>

                   <Grid xs={12}>
                       <p></p>
                   </Grid>

                <Grid xs={12}>
                       <p></p>
                   </Grid>


                <Grid container xs={12}>
            <div className="accordionWrapper">
                <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="accordionLabel"
              >
                <Typography className={accordeonClasses.heading}>
                  <h2 className="accordionLabel">
                  Rezultate obținute de candidați la concursul de admitere - subofițeri 2021
                </h2></Typography>
              </AccordionSummary>
              <AccordionDetails>
                    <div >  
                        <ul className="accordionUl">
                          <li>
                            <a className="subTitle" href={subIntendenta} target="_blank">👉 Rezultate obținute de candidați la concursul de admitere - subofițeri INTENDENȚĂ - 01.08.2021</a>
                          </li>

                          <li>
                            <a className="subTitle" href={subAdministratie} target="_blank">👉 Rezultate obținute de candidați la concursul de admitere - subofițeri ADMINISTRAȚIE - 01.08.2021</a>

                          </li>

                          <li>
                          <a className="subTitle" href={subConstructii} target="_blank">👉 Rezultate obținute de candidați la concursul de admitere - subofițeri CONSTRUCȚII - 01.08.2021</a>
                          </li>

                        </ul>

                    </div>
              
              </AccordionDetails>
            </Accordion>

            </div>                
                </Grid>

               
                <Grid container xs={12} justify="center">
               

                
                <Grid xs={12}>
                       <p></p>
                   </Grid>

                <Grid container item xs={12} justify="center">
                        <div className="linkContainer1">
                            <a className="subTitle" href={locuriDisponibile} target="_blank">Locuri rămase disponibile pentru sesiunea septembrie 2021</a>
                        </div>
                </Grid>

                <Grid xs={12}>
                       <p></p>
                   </Grid>

                <Grid container item xs={12} justify="center">
                        <div className="linkContainer1">
                            <a className="subTitle" href={locuriAdmitere} target="_blank">Locuri disponibile pentru anul 2021-2022</a>
                        </div>
                </Grid>

                   <Grid xs={12}>
                       <p></p>
                   </Grid>
                <Grid container item xs={12} justify="center">

                        <div className="linkContainer2">
                            <a href={metodologie} target="_blank">Metodologia organizării și desfășurării admiterii în Școala Militară de Maiştri Militari și Subofiţeri de Logistică pentru anul 2021-2022</a>
                        </div>
                </Grid>

                <Grid xs={12}>
                       <p></p>
                   </Grid>
                <Grid container item xs={12} justify="center">

                        <div className="linkContainer2">
                            <a href={anexaMetodologie} target="_blank">Anexa la Metodologia organizării și desfășurării admiterii în Școala Militară de Maiştri Militari și Subofiţeri de Logistică pentru anul 2021-2022</a>
                        </div>
                </Grid>

                </Grid>
               
            </div>

    )
}

export default InfoAdmitere
