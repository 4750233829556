import React from 'react'
import "./misiune.css"

function Misiune() {
    return (
        <div className="misiune">
        <h1>Misiune</h1>
            <p className="misiune-text">
            Şcoala Militară de Maiştri Militari şi Subofiţeri de Logistică, subordonată Comandamentului logistic întrunit, asigură  formarea iniţială prin pregătire postliceală a maiştrilor militari şi a subofiţerilor de logistică ca luptători, lideri ai structurilor de la baza ierarhiei militare şi specialişti în asigurarea serviciilor de sprijin logistic, exploatarea, repararea, întreţinerea echipamentelor din dotare, potrivit nevoilor armatei şi altor beneficiari, atât la pace, cât şi la criză şi război, precum şi participarea la formarea profesională continuă a personalului de logistică.
           </p>
        </div>
    )
}

export default Misiune
