import React from 'react'
import "./comandanti.css"

function Comandanti() {
    return (
        <div className="comandanti-wrapper">

        <div className="comandanti">
            <h3>Comandanții şcolii</h3>

          <div className="row-wrapper">
            <div className="row1">
            <span className="row1-nume">Nume şi prenume</span>
            <span className="row1-perioada">Perioada</span>
            </div>


            <div className="row2">
            <span className="row2-nume">Colonel doctor Marian - Daniel FĂŞIE</span>
            <span className="row2-perioada">2020 - prezent</span>
            </div>
         </div>
         </div>   
        </div>
    )
}

export default Comandanti
