
import React from 'react';
import Carousel from 'react-bootstrap/Carousel'
import "./slideshow.css";
import slideshow1 from "./1-land.jpg";
import slideshow2 from "./2-land.jpg";
import slideshow3 from "./3-land.jpg";


const Slideshow = () => {
    return (
        <Carousel className="slideshow">
        <Carousel.Item>
          <img
            className="slideshow-image"
            src={slideshow1}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="slideshow-image"
            src={slideshow2}
            alt="Third slide"
          />
    
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="slideshow-image"
            src={slideshow3}
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
    )
}

export default Slideshow;