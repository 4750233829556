import React from 'react'
import "./viziune.css"

function Viziune() {
    return (
        <div className="viziune">
        <h1>Viziune</h1>
            <p className="viziune-text">
            Şcoala Militară de Maiştri Militari şi Subofiţeri de Logistică îşi propune să promoveze o educaţie modernă, care să îi ajute pe elevi să devină specialişti militari în domeniile funcţionale ale logisticii, să promoveze valorile şi tradiţiile poporului român, să fie factori activi în consolidarea democraţiei în România. 
Viziunea cuprinde două contexte prin care ne definim locul în cadrul sistemului de învăţământ militar:
<br/>-	ca organizaţie care funcţionează pe baza anumitor reguli, având un rol important în realizarea interacţiunii: educaţie, competivitate profesională şi cetăţenia democratică;
<br/>-	ca “motor” ale domeniilor funcţionale ale logisticii, care prin rezultatul muncii propulsează pe o traiectorie ascendentă realizările specifice ale acestora.
</p>
<p className="viziune-text">
Oferta educaţională a şcolii este concepută ca o simbioză a viziunii grupurilor de interes: instructori (profesori), elevi şi beneficiari. Elementele principale ale strategiei noastre sunt:
<br/>-	creşterea prestigiului şcolii prin aşezarea elevului şi a preocupărilor sale în centrul procesului de învăţământ;
<br/>-	formarea şi întărirea motivaţiei elevilor pentru învăţarea şi informarea continuă;
<br/>-	menţinerea, modernizarea şi optimizarea continuă a procesului educaţional, atât din punct de vedere a actului didactic cât şi a bazei logistice didactice.
            </p>
<p className="viziune-text">
Şcoala Militară de Maiştri Militari şi Subofiţeri de Logistică, prin viziunea propusă, urmăreşte formarea unui absolvent cu cunoştinţe solide, aptitudini, competenţe şi deprinderi, capabil să se adapteze la schimbările tehnologice ale mediului militar şi la cerinţele determinate de calitatea de membru al NATO şi UE . 
  </p>
<p className="viziune-text">
În acest sens Şcoala Militară de Maiştri Militari şi Subofiţeri de Logistică va realiza:
<br/>-	înaltă calitate, o puternică motivaţie, angajare şi atitudini profesionale din partea cadrelor didactice, instructorilor militari, personalului didactic auxiliar şi nedidactic;
<br/>-	asigurarea climatului optim necesar studiului şi pregătirii practice de specialitate;
<br/>-	asigurarea bazei logistice didactice moderne necesare pregătirii de specialitate;
<br/>-	utilizarea mijloacelor şi echipamentelor moderne ale sistemului informatic, a reţelei de comunicaţii între diferitele structuri din şcoală şi între acestea şi beneficiari.
            </p>    
        </div>
    )
}

export default Viziune
