import "./contact.css"
import React from 'react'

function Contact() {
    return (
        <div className="contact">
        <h1>Contact</h1>
            <iframe width="50%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
             src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=Strada%20Crinului%202,%20Chitila%20077045+(%C5%9ECOALA%20MILITAR%C4%82%20DE%20MAI%C5%9ETRI%20MILITARI%20%C5%9EI%20SUBOFI%C5%A2ERI%20DE%20LOGISTIC%C4%82%20%E2%80%9EGHEORGHE%20LAZ%C4%82R%E2%80%9D)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
             </iframe>
        <div className="contact-bottom">
            <div className="contact-bottom--left">
                <h2 id="audienta">Program audienţe</h2>
                <span id="col">
                    <span id="cdt">Comandant</span>
                    <span id="zi">Miercuri</span>
                    <span id="ora">13.00 - 15.00</span>
                </span>
            </div>
            <div className="contact-bottom--right">
                <h2 id="contact">Contact</h2>
                <span><strong>Adresa:</strong>Strada Crinului 2, Chitila 077045</span>
                <span><strong>Telefon:</strong>(0214) 363.484</span>
                <span><strong>Email:</strong>um02240@mapn.ro</span>

            </div>
        </div>
        </div>
    )
}

export default Contact
