import React from 'react'
import "./anunturi.css"
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

//pdf-uri corecte
import asistent from './asistent.PDF';
import contabil from './contabil.PDF';
import ingrijitor from './ingrijitor.PDF';
import referent from './referent.PDF';
import referentPier from './referentPier.PDF';
import rezultateAsistent from '../home/rezultateAsistent.pdf';
import anunt1 from './anunt1.pdf';
import anunt2 from './anunt2.pdf';
import anunt3 from './anunt3.pdf';
import rezultateIngrijitor1 from './rezultateIngrijitor1.pdf';
import rezultateReferent from './rezultateReferent.pdf';
import rezultateReferentCuriculum from './rezultateReferentCuriculum.pdf';
import rezultateScrisIngrijitor from './rezultateScrisIngrijitor.pdf';
import rezultateActe from './rezultateActe.pdf';
import rezultateContabil from './rezultateContabil.pdf';
import rezultateSalar from './rezultateSalar.pdf';
import rezultateIngrijitor2 from './rezultateIngrijitor2.pdf';
import rezultateFinale2 from './rezultateFinale2.pdf';
import anunt03122021 from './anunt-03122021.pdf';
import anunt14122021 from './anunt14122021.PDF';     // aici se import fisierele pdf
import anunt20211220 from './anunt20211220.pdf';
import anunt20211222 from './anunt20211222.pdf';
import anunt20211224 from './anunt20211224.pdf';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: "5px",
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

const Anunturi = () => {



      const accordeonClasses = useStyles();

    return (

            <div className="info">

              <Grid container xs={12}>

      {/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}
          {/* Asta se copie pt anunt nou */}


        
          <Grid container item xs={12} justify="center">
                       <div className="linkContainer1">
                            <a className="subTitle" href={anunt20211224} target="_blank">
                            Anunț cu rezultatele probei scrise la concursul pentru încadrarea postului de
                            Referent de specialitate gr. III la biroul management şi asigurarea calităţii educaţiei
                            </a>
                       </div>                      
                </Grid>

                <Grid xs={12}>
                       <p></p>
                   </Grid>



   {/* %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%% */}


   <Grid container item xs={12} justify="center">
                       <div className="linkContainer1">
                            <a className="subTitle" href={anunt20211222} target="_blank">
                            Anunț cu rezultatele probei scrise la concursul pentru ocuparea posturilor didactice/catedrelor
vacante
                            </a>
                       </div>                      
                </Grid>

                <Grid xs={12}>
                       <p></p>
                   </Grid>



                <Grid container item xs={12} justify="center">
                       <div className="linkContainer1">
                            <a className="subTitle" href={anunt20211220} target="_blank">
                            Anunț cu rezultatele selecţiei dosarelor de concurs pentru încadrarea postului de
                            Referent de specialitate gr. III la biroul management şi asigurarea calităţii educaţiei
                            </a>
                       </div>                      
                </Grid>

                <Grid xs={12}>
                       <p></p>
                   </Grid>




                <Grid container item xs={12} justify="center">
                       <div className="linkContainer1">
                            <a className="subTitle" href={anunt14122021} target="_blank">
                            Anunț organizare consurs pentru ocuparea a două posturi didactice
                            </a>
                       </div>                      
                </Grid>

                <Grid xs={12}>
                       <p></p>
                   </Grid>


              <Grid container item xs={12} justify="center">
                       <div className="linkContainer1">
                            <a className="subTitle" href={anunt03122021} target="_blank">
                            Anunț post referent de specialitate gr. III la biroul management şi asigurarea calităţii educaţiei
                            </a>
                       </div>                      
                </Grid>

                <Grid xs={12}>
                       <p></p>
                   </Grid>


             
              
              <Grid container item xs={12} justify="center">
                       <div className="linkContainer1">
                          <a className="subTitle" href={rezultateFinale2} target="_blank">
                          REZULTATELE FINALE</a>
                                                  </div>                      
                </Grid>

                <Grid xs={12}>
                       <p></p>
                   </Grid>


              <Grid container item xs={12} justify="center">
                       <div className="linkContainer1">
                          <a className="subTitle" href={rezultateIngrijitor2} target="_blank">
                          Rezultatele probei practice la concursul organizat pentru încadrarea postului de Îngrijitor debutant la grupa administraţie                          </a>   
                        </div>                      
                </Grid>

                <Grid xs={12}>
                       <p></p>
                   </Grid>









                
              <Grid container item xs={12} justify="center">
                       <div className="linkContainer1">
                          <a className="subTitle" href={rezultateSalar} target="_blank">
                          Rezultatele probei scrise la concursul organizat pentru încadrarea postului de Contabil II la compartimentul salarizare şi decontări 
                          </a>   
                        </div>                      
                </Grid>

                <Grid xs={12}>
                       <p></p>
                   </Grid>

              <Grid container item xs={12} justify="center">
                       <div className="linkContainer1">
                          <a className="subTitle" href={rezultateContabil} target="_blank">
                          Rezultatele selecţiei dosarelor de concurs pentru încadrarea postului de Contabil II la compartimentul salarizare şi decontări, cu o vechime în specialitatea studiilor de 6 luni    </a>                    </div>
                          
                </Grid>

                <Grid xs={12}>
                       <p></p>
                   </Grid>


              <Grid container item xs={12} justify="center">
                       <div className="linkContainer1">
                          <a className="subTitle" href={rezultateActe} target="_blank">
                          Rezultatele probei scrise la concursul organizat pentru încadrarea postului de Referent de specialitate gr. III la compartimentul gestiune acte de studii şi documente şcolare                      </a>
                        </div>
                          
                </Grid>

                <Grid xs={12}>
                       <p></p>
                   </Grid>




              <Grid container item xs={12} justify="center">
                       <div className="linkContainer1">
                          <a className="subTitle" href={rezultateScrisIngrijitor} target="_blank">
                          Rezultatele probei scrise la concursul organizat pentru încadrarea postului de Îngrijitor debutant la grupa administraţie                         
                      </a>
                        </div>
                          
                </Grid>

                <Grid xs={12}>
                       <p></p>
                   </Grid>





              <Grid container item xs={12} justify="center">
                       <div className="linkContainer1">
                          <a className="subTitle" href={rezultateReferentCuriculum} target="_blank">
                          Rezultatele probei scrise la concursul organizat pentru încadrarea postului de Referent de specialitate gr. III la biroul proiectare, implementare, evaluare şi revizuire curriculum educaţional                          </a>
                           </div>
                          
                </Grid>

                <Grid xs={12}>
                       <p></p>
                   </Grid>





              <Grid container item xs={12} justify="center">
                       <div className="linkContainer1">
                          <a className="subTitle" href={rezultateReferent} target="_blank">
                          Rezultatele selecţiei dosarelor de concurs pentru încadrarea postului de Referent de specialitate gr. III la compartimentul gestiune acte de studii şi documente şcolare;                       
                          </a>
                           </div>
                          
                </Grid>

                <Grid xs={12}>
                       <p></p>
                   </Grid>









              <Grid container item xs={12} justify="center">
                       <div className="linkContainer1">
                          <a className="subTitle" href={rezultateIngrijitor1} target="_blank">
                            Rezultatele selecţiei dosarelor de concurs pentru încadrarea postului de Îngrijitor debutant la grupa administraţie                            </a>
                        </div>
                </Grid>

                <Grid xs={12}>
                       <p></p>
                   </Grid>

                
              <Grid container item xs={12} justify="center">
                       <div className="linkContainer1">
                          <a className="subTitle" href={anunt3} target="_blank">Rezultatele probei scrise la concursul organizat pentru încadrarea postului de Asistent medical la Cabinetul medical (cu staţionar)</a>
                        </div>
                </Grid>

                <Grid xs={12}>
                       <p></p>
                   </Grid>


              <Grid container item xs={12} justify="center">
                       <div className="linkContainer1">
                          <a className="subTitle" href={anunt1} target="_blank">Rezultatele selecţiei dosarelor de concurs pentru încadrarea postului de Referent de specialitate gr. III la biroul proiectare, implementare, evaluare şi revizuire curriculum educaţional</a>
                        </div>
                </Grid>

                <Grid xs={12}>
                       <p></p>
                   </Grid>

              <Grid container item xs={12} justify="center">
                       <div className="linkContainer1">
                          <a className="subTitle" href={rezultateAsistent} target="_blank">Rezultatele selecţiei dosarelor de concurs pentru încadrarea postului de Asistent medical la Cabinetul medical</a>
                        </div>
                </Grid>

                <Grid xs={12}>
                       <p></p>
                   </Grid>

                <Grid container item xs={12} justify="center">
                       <div className="linkContainer1">
                          <a className="subTitle" href={ingrijitor} target="_blank">Anunt ingrijitor</a>
                        </div>
                </Grid>

                <Grid xs={12}>
                       <p></p>
                   </Grid>

                <Grid container item xs={12} justify="center">
                <div className="linkContainer1">
                <a className="subTitle" href={contabil} target="_blank">Anunt contabil</a>
                </div>
                </Grid>

                <Grid xs={12}>
                       <p></p>
                   </Grid>

                <Grid container item xs={12} justify="center">
                <div className="linkContainer1">
                <a className="subTitle" href={asistent} target="_blank">Anunt asistent medical</a>
                </div>
                </Grid>

                <Grid xs={12}>
                       <p></p>
                   </Grid>

                <Grid container item xs={12} justify="center">
                <div className="linkContainer1">
                <a className="subTitle" href={referent} target="_blank">Anunt referent la compartimentul gestiune acte de studii si documente scolare</a>
                </div>
                </Grid>

                <Grid xs={12}>
                       <p></p>
                   </Grid>

                <Grid container item xs={12} justify="center">
                <div className="linkContainer1">
                <a className="subTitle" href={referentPier} target="_blank">Anunt referent birou P.I.E.R. curriculum educational</a>
                </div>
                </Grid>

              </Grid>


           


            </div>

    )
}

export default Anunturi
